<template>
  <div class="hide-scrollbars absolute left-0 top-0 h-[100dvh] w-full overflow-scroll bg-rsbrand-blue-dk">
    <nav class="relative z-30 w-full">
      <div class="z-10 border-b border-white border-opacity-20 px-12">
        <div class="mx-auto flex max-w-screen-xl justify-between">
          <Link href="/" class="flex items-center gap-4">
            <img class="my-7 h-11" src="/images/kirsch-lockup-white.svg" />
            <div class="text-3xl font-extralight text-white text-opacity-20">/</div>
            <div class="text-3xl text-kirsch-aqua-highlight">Occupier Tools</div>
          </Link>
          <div class="hidden items-center gap-12 tracking-wide text-white md:flex"></div>
        </div>
      </div>
    </nav>
    <div class="relative overflow-hidden bg-kirsch-blue-dark">
      <div
        class="relative z-10 -mt-[102px] h-[552px] overflow-hidden bg-cover bg-center bg-no-repeat pt-[102px]"
        :style="{
          backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
        }"
      >
        <div
          class="relative z-10 mx-auto flex h-full w-[360px] flex-col justify-between font-light text-white sm:w-[720px] md:flex-row lg:w-[1080px]"
        >
          <div class="flex grow md:mr-32 md:flex-initial md:self-center">
            <div class="self-center text-5xl leading-tight md:text-6xl">People, Places, Potential</div>
          </div>
          <div class="flex justify-end md:grow">
            <img class="w-[260px] self-end sm:w-[260px] md:w-[360px]" src="/images/kirsch-emblem-2c.svg" />
          </div>
        </div>
      </div>
      <div
        class="absolute left-0 top-0 z-0 h-full w-full bg-cover bg-center bg-no-repeat"
        :style="{
          backgroundImage: 'url(/images/location-restaurant-interior.jpg)'
        }"
      ></div>
    </div>
    <div class="overflow-hidden bg-kirsch-tan-base">
      <div
        class="relative mx-auto flex w-[360px] sm:w-[722px] lg:w-[1082px]"
        :style="{ backgroundImage: 'url(\'/images/pattern-pinstripe-black10pct.png\')' }"
      >
        <div class="absolute -left-[600px] h-[360px] w-[600px] bg-kirsch-purple-base"></div>
        <div class="flex grow items-center py-16 pl-8 sm:pl-16">
          <div class="max-w-3xl">
            <div class="space-y-2">
              <div class="w-full text-lg font-semibold text-kirsch-bluberry-primary">Sign In</div>
              <div class="flex gap-4">
                <button
                  class="focus:shadow-outline block w-full rounded-md bg-kirsch-purple-base px-4 py-3 text-center font-semibold text-white shadow-md hover:bg-kirsch-aqua-highlight hover:text-kirsch-bluberry-primary hover:shadow-lg"
                  @click="gotohome"
                >
                  Go to home
                </button>
                <button
                  class="focus:shadow-outline block w-full rounded-md bg-kirsch-purple-base px-4 py-3 text-center font-semibold text-white shadow-md hover:bg-kirsch-aqua-highlight hover:text-kirsch-bluberry-primary hover:shadow-lg"
                  @click="getuser"
                >
                  Get User
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden bg-kirsch-tan-base">
      <div
        class="relative mx-auto flex w-[360px] sm:w-[722px] lg:w-[1082px]"
        :style="{ backgroundImage: 'url(\'/images/pattern-pinstripe-black10pct.png\')' }"
      >
        <div
          class="hidden h-[360px] w-[360px] flex-none sm:block"
          :style="{ backgroundImage: 'url(\'/images/pattern-grid-dkblue-transparent.png\')' }"
        ></div>
        <div class="flex grow px-8 pb-8 lg:pb-0 lg:pl-32 lg:pr-8">
          <div class="self-center text-lg font-light text-kirsch-blue-dark lg:text-2xl">
            Premier retail brands and property owners opt for Kirsch Agency as their trusted local ally.
            Drawing upon decades of expertise and relentless market research, we deliver clients a thorough
            understanding of local opportunities, along with the strategic leverage needed to seize them.
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden bg-rsbrand-blue-dk">
      <div
        class="relative mx-auto flex h-56 w-[362px] flex-col space-y-6 py-16 sm:w-[722px] lg:w-[1082px] lg:pb-24"
        :style="{ backgroundImage: 'url(\'/images/pattern-pinstripe-white15pct.png\')' }"
      ></div>
    </div>
    <footer>
      <div class="z-10 border-t border-white border-opacity-20 px-12">
        <div class="mx-auto flex max-w-screen-xl items-center justify-between">
          <a href="https://kirsch.agency" target="_blank">
            <img class="my-7 h-7" src="/images/kirsch-logotype-white.svg" />
          </a>
          <div class="my-7 space-y-6 self-center text-white text-opacity-50">
            <div class="space-y-0">
              <div class="text-sm">&copy; Kirsch.Agency 2024</div>
            </div>
          </div>
        </div>
        <div class="mx-auto max-w-screen-xl space-y-3">
          <div class="self-center text-xs tracking-wide text-white text-opacity-50">A Partner of</div>
          <a class="inline-block" href="https://tirca.org/" target="_blank">
            <img class="mx-auto h-20" src="/images/tirca-logo-white.png" />
          </a>
        </div>
      </div>
      <div class="px-12 pb-12"></div>
    </footer>
  </div>
</template>

<script>
const stripeAPIKey = process.env.VUE_APP_STRIPE_API_KEY
const stripe = require('stripe')(stripeAPIKey)
import { mapState } from 'vuex'
import CardPricing from '@/components/CardPricing'
import PriceGallery from '@/features/pricegallery/PriceGallery.vue'
export default {
  setup() {},
  components: { CardPricing, PriceGallery },
  data: function () {
    return {
      signUpOn: true,
      createOrgMode: false,
      stripeCSSesion: false,
      stripeSubscription: false,
      stripeCSReady: false,
      stripeCSError: false,
      // auth0user: this.$auth0.user,
      // auth0error: this.$auth0.error,
      // auth0isAuthenticated: this.$auth0.isAuthenticated,
      // auth0isLoading: this.$auth0.isLoading,
      createOrgForm: false,
      createOrgFormSubmitted: false,
      createOrgFormError: false,
      saveInProgress: false,
      newOrg: {
        invcode: '',
        name: '',
        description: '',
        producttype: ''
      },
      newUser: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        defaultlat: '41.8863',
        defaultlng: '-87.6351',
        currency: 'USD',
        spaceqtytype: 'SqFt',
        acttype: '2'
      }
    }
  },
  computed: {
    ...mapState(['envData', 'userData', 'userAuthenticated', 'statsPlatform'])
  },
  mounted() {
    if (this.userData) {
      this.$router.push('/home')
    }
  },
  watch: {
    // auth0user() {
    //   if (this.auth0user) {
    //     this.$store.dispatch('fetchUserData', this.auth0user).then((result) => {
    //       if (!result) {
    //         this.$auth0.logout({ returnTo: window.location.origin })
    //       } else {
    //         this.$router.push('/home')
    //       }
    //     })
    //   }
    // }
  },
  methods: {
    gotohome() {
      this.$router.push('/home')
    },
    getuser() {
      this.$store.dispatch('fetchUserData').then((result) => {
        if (!result) {
          console.log('fetchUserData failed')
        } else {
          console.log('fetchUserData success', result)
        }
      })
    }
    // login() {
    //   this.$auth0.loginWithRedirect()
    // },
    // logout() {
    //   this.$auth0.logout({ returnTo: window.location.origin })
    // }
  }
}
</script>
