import { createRouter, createWebHistory } from 'vue-router'
import { createAuth0, authGuard } from '@auth0/auth0-vue'
import Login from '../views/Login.vue'
import Stats from '../views/Stats.vue'
import ClientPortalHome from '../views/ClientPortalHome.vue'
import MarketReport from '../views/MarketReport.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/:stripecsid',
    name: 'loginsignup',
    component: Login
  },
  {
    path: '/stats',
    name: 'stats',
    component: Stats
  },
  {
    path: '/marketreport/:marketreportuid',
    name: 'marketreport',
    component: MarketReport
  },
  {
    path: '/client/:clientuid',
    name: 'clientportalhome',
    component: ClientPortalHome
  },
  {
    path: '/client/:clientuid/tours',
    name: 'clientportaltours',
    component: () => import('../views/ClientPortalTours.vue')
  },
  {
    path: '/client/:clientuid/tours/:touruid',
    name: 'clientportaltour',
    component: () => import('../views/ClientPortalTour.vue')
  },
  {
    path: '/client/:clientuid/pipeline',
    name: 'clientportalpipeline',
    component: () => import('../views/ClientPortalPipeline.vue')
  },
  {
    path: '/areatoken/:propuid',
    name: 'areatoken-public',
    component: () => import('../views/AreaToken.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/mapinput',
    name: 'mapinput',
    component: () => import('../views/MapInput.vue')
  },
  {
    path: '/mapinputnew',
    name: 'mapinputnew',
    component: () => import('../features/mapview/MapView.vue')
  },
  {
    path: '/mapinput/:locuid',
    name: 'ToolsLoc',
    component: () => import('../views/ToolsLoc.vue')
  },
  {
    path: '/mappipeline',
    name: 'mappipeline',
    component: () => import('../views/MapPipeline.vue')
  },
  {
    path: '/mappipelinenew',
    name: 'mappipelinenew',
    component: () => import('../features/pipelineview/PipelineView.vue')
  },
  {
    path: '/toolsclients',
    name: 'toolsclients',
    component: () => import('../views/ToolsClients.vue')
  },
  {
    path: '/toolsclients/:clientuid',
    name: 'toolsclient',
    component: () => import('../views/ToolsClient.vue')
  },
  {
    path: '/toolsmarketreports',
    name: 'toolsmarketreports',
    component: () => import('../views/ToolsMarketReports.vue')
  },
  {
    path: '/toolsmarketreports/:marketreportuid',
    name: 'toolsmarketreport',
    component: () => import('../views/MapMarketReport.vue')
  },
  {
    path: '/toolsmarkets',
    name: 'toolsmarkets',
    component: () => import('../views/ToolsMarkets.vue')
  },
  {
    path: '/toolsattributes',
    name: 'toolsattributes',
    component: () => import('../views/ToolsAttributes.vue')
  },
  {
    path: '/toolscategories',
    name: 'toolscategories',
    component: () => import('../views/ToolsCategories.vue')
  },
  {
    path: '/toolsteam',
    name: 'toolsteam',
    component: () => import('../views/ToolsTeam.vue')
  },
  {
    path: '/metrics',
    name: 'metrics',
    component: () => import('../views/Metrics.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
